<template>
  <div>
    <b-alert
      v-if="symbol == 'bsc-usd'"
      show
      variant="warning"
      class="p-4"
    >
      {{ $t('withdrawlForThisTokenIsNotAllowed') }}
    </b-alert>
    <b-overlay
      v-if="symbol != 'bsc-usd'"
      :show="show"
      spinner-variant="primary"
      spinner-type="grow"
      spinner-small
      rounded="sm"
    >
      <b-card no-body>
        <b-row>
          <b-col
            sm="6"
            class="card-border"
          >
            <b-card-body>
              <div class="text-center text-md-left mt-3">
                <h3 class="text-primary">
                  {{ $t('withdraw-title') }}
                </h3>
                <p>{{ $t('withdraw-dec', { currency: coin }) }}</p>
              </div>
              <b-img
                fluid
                :src="require('@/assets/images/illustration/pricing-Illustration.svg')"
                class="pricing-trial-img"
                alt="svg img"
              />
            </b-card-body>
          </b-col>
          <b-col sm="6">
            <b-col
              v-if="this.$route.params.coin_symbol != 'usdt' && this.$route.params.coin_symbol != 'darik'"
            >
              <b-alert
                show
                variant="danger"
                class="text-center mt-5 mx-2 p-3"
              >
                <span>{{ $t('depositToThisWalletIsNotPossibleForNow') }}</span>
              </b-alert>
            </b-col>
            <b-card-body v-if="this.$route.params.coin_symbol == 'usdt' || this.$route.params.coin_symbol == 'darik'">
              <b-alert
                class="p-2"
                show
                variant="danger"
              >
                <p>{{ $t('withdraw-warning-dec', { currency: coin }) }}</p>
              </b-alert>
              <ValidationObserver v-slot="{ invalid }">
                <b-form class="mt-2">
                  <b-row>
                    <b-col cols="12">
                      <b-form-group
                        label-for="coin"
                        :label="$t('coin')"
                      >
                        <b-form-input
                          id="coin"
                          v-model="coin"
                          readonly
                          name="coin"
                          :placeholder="$t('coin')"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col cols="12">
                      <b-form-group
                        label-for="network"
                        :label="$t('Network')"
                      >
                        <b-form-select
                          id="network"
                          v-model="network"
                          name="network"
                          :options="networks"
                          :placeholder="$t('Network')"
                          @change="changeNetwork"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col cols="12">
                      <b-form-group
                        label-for="fee"
                        :label="$t('fee')"
                      >
                        <b-form-input
                          id="fee"
                          v-model="fee"
                          readonly
                          name="fee"
                          :placeholder="$t('fee')"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col cols="12">
                      <validation-provider
                        v-slot="{ errors }"
                        :name="$t('destinationAddress')"
                        :rules="`required|cryptoWalletAddress:${symbol}`"
                      >
                        <b-form-group
                          label-for="destinationAddress"
                          :label="$t('destinationAddress')"
                        >
                          <b-form-input
                            id="destinationAddress"
                            v-model="destinationAddress"
                            name="destinationAddress"
                            :placeholder="$t('destinationAddress')"
                            autocomplete="off"
                          />
                          <span class="text-danger">{{ $t(errors[0]) }}</span>
                        </b-form-group>
                      </validation-provider>
                    </b-col>
                    <b-col cols="12">
                      <validation-provider
                        v-slot="{ errors }"
                        :name="$t('Amount')"
                        rules="required"
                      >
                        <b-form-group
                          :label="$t('Amount')"
                          label-for="Amount"
                        >
                          <b-form-input
                            id="Amount"
                            v-model.number="price"
                            name="Amount"
                            :placeholder="$t('Amount')"
                          />
                          <span class="text-danger">{{ errors[0] }}</span>
                        </b-form-group>
                      </validation-provider>
                    </b-col>
                    <b-col class="d-flex flex-column justify-content-center">

                      <b-button
                        variant="success"
                        @click="showBarcode =!showBarcode"
                      >
                        {{ $t('barcodeScanning') }}
                      </b-button>
                      <p
                        class="d-flex justify-content-center text-danger mt-1 error"
                      >
                        {{ $t(error) }}
                      </p>

                      <qrcode-stream
                        v-if="showBarcode"
                        @decode="onDecode"
                        @init="onInit"
                      />

                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col>
                      <b-button
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        data-toggle="modal"
                        data-target="#myModal"
                        variant="primary"
                        class="mt-2 btn-block"
                        :disabled="invalid"
                        @click="setInvoiceDetails"
                      >
                        {{ $t('send') }}
                      </b-button>
                    </b-col>
                  </b-row>
                </b-form>
              </ValidationObserver>
            </b-card-body>
          </b-col>
        </b-row>
      </b-card>
      <b-card
        v-if="items.length !== 0"
        class="d-none d-sm-block"
        :title="$t('lastTransactions')"
      >
        <b-row align-h="center">
          <b-col cols="12">
            <b-table
              :items="items"
              :fields="fields"
              striped
              sticky-header="1000px"
            >
              <template #cell(created_at)="data">
                <span>{{ getFormattedDate(data.item["created_at"]) }}</span>
              </template>
              <template #cell(status)="data">
                <div class="d-flex align-items-center">
                  <b-button
                    :variant="data.item['status'] === 'pending' ? 'warning' : data.item['status'] === 'success' ? 'success' :data.item['status'] === 'in_progress'? 'outline-info': 'danger'"
                    size="sm"
                    style="display: block; width: 140px"
                  > {{ $t('withdraw' + '-' + data.item['status']) }}</b-button>
                  <feather-icon
                    v-if="data.item['status'] === 'rejected' && data.item['comment_reject']"
                    :id="`popover-button-variant-${data.item.id}`"
                    icon="HelpCircleIcon"
                    class="cursor-pointer mx-1"
                    size="20"
                    href="#"
                    tabindex="0"
                  />
                  <b-popover
                    placement="top"
                    :target="`popover-button-variant-${data.item.id}`"
                    variant="secondary"
                    triggers="focus"
                  >
                    {{ data.item['comment_reject'] ? data.item['comment_reject']:'' }}
                  </b-popover>
                </div>
              </template>
              <template #cell(transaction_id)="data">
                <b-button
                  variant="info "
                  size="sm"
                  style="display: block"
                  :disabled="!data.item.transaction_id"
                  @click="() => triggerTransactionModal(data.item)"
                >
                  {{ $t('transaction_id') }}
                </b-button>
              </template>
            </b-table>
          </b-col>
          <b-col cols="auto">
            <b-pagination
              v-if="totalTransactions > perPageTransactions"
              v-model="currentpageTransactions"
              :total-rows="totalTransactions"
              :per-page="perPageTransactions"
              align="fill"
              size="sm"
              class="mb-1"
            />
          </b-col>
        </b-row>
      </b-card>

      <b-card
        class="d-sm-none"
        :title="$t('lastTransactions')"
      >
        <b-row align-h="center">
          <b-col cols="12">
            <div
              v-for="(item, index) in items"
              :key="index"
              :class="$store.state.appConfig.layout.skin === 'dark' ? 'card-bg' : 'transaction-card'"
              class="mb-2"
            >
              <div class="d-flex justify-content-between">
                <div>{{ $t('coin') }}</div>
                <h5>{{ item.currency_name }}</h5>

              </div>
              <div class="d-flex justify-content-between mt-1">
                <div>{{ $t('Network') }}</div>
                <h5>{{ item.network_name }}</h5>

              </div>
              <div class="d-flex justify-content-between mt-1">
                <div>{{ $t('amount') }}</div>
                <h5>{{ item.amount }}</h5>

              </div>
              <div class="d-flex justify-content-between mt-1">
                <div>{{ $t('date') }}</div>
                <h5>{{ getFormattedDate(item.date) }}</h5>

              </div>
              <div class="d-flex justify-content-between mt-1">
                <div>{{ $t('status') }}</div>
                <div class="d-flex align-items-center">
                  <feather-icon
                    v-if="item.status === 'rejected' && item.comment_reject"
                    :id="`popover-button-variant-${item.id}+mobile`"
                    icon="HelpCircleIcon"
                    class="cursor-pointer"
                    size="20"
                    href="#"
                    tabindex="0"
                  />
                  <b-popover
                    placement="top"
                    :target="`popover-button-variant-${item.id}+mobile`"
                    variant="secondary"
                    triggers="focus"
                  >
                    {{ item.comment_reject ? item.comment_reject:'' }}
                  </b-popover>
                  <div style="margin: 0 5px;" />
                  <b-button
                    :variant="item.status === 'pending' ? 'warning' : item.status === 'success' ? 'success' : item.status === 'in_progress'? 'outline-info':'danger'"
                    size="sm"
                    style="display: block; width: 140px"
                  > {{ $t('withdraw' + '-' + item.status) }}</b-button>
                </div>
              </div>
              <div class="d-flex justify-content-between mt-2">
                <b-button
                  variant="info"
                  size="sm"
                  style="width:100%"
                  :disabled="!item.transaction_id"
                  @click="() => triggerTransactionModal(item)"
                >
                  {{ $t('transaction_id') }}
                </b-button>
              </div>
            </div>
          </b-col>
          <b-col cols="auto">
            <b-pagination
              v-if="totalTransactions > perPageTransactions"
              v-model="currentpageTransactions"
              :total-rows="totalTransactions"
              :per-page="perPageTransactions"
              align="fill"
              size="sm"
              class="mb-1"
            />
          </b-col>
        </b-row>
      </b-card>

      <b-modal
        v-model="confirmModal"
        :title="$t('invoice_confirm')"
        centered
        size="lg"
      >
        <template>
          <b-card
            class="mb-0 invoiceModal"
            :title="$t('invoice.brandName')"
          >
            <b-row
              align-h="end"
              class="mt-n2 mb-1"
            >
              <b-col cols="auto">
                <span class="text-dark">
                  {{
                    new Date()
                      .toLocaleString(this.$i18n.locale === 'fa' ? 'fa-IR' : 'en-US',
                                      {
                                        dateStyle: 'short',
                                        timeStyle: 'short',
                                      })
                  }}
                </span>
              </b-col>
            </b-row>
            <b-row
              class="row-bg p-1 font-weight-bold"
              style="background-color: #e3ffd7;"
            >
              <b-col
                cols="12"
                class="text-primary"
              >
                <!-- <span>{{ $t('invoice.invoiceDetails') }}</span> -->
                <span>{{ $t('invoice.withdrawalFromYourWallet') }}</span>
              </b-col>
              <div
                v-if="modalInvoiceColumns"
                class="table-invoice-crypto-withdraw w-100 mt-1"
              >
                <b-table
                  responsive
                  stacked
                  :items="modalInvoiceColumns"
                  :fields="invoiceHeader"
                >
                  <template
                    #cell(fee)="data"
                  >
                    <div>
                      {{ data.item.fee }}
                    </div>
                    <div
                      class="text-danger"
                    >
                      <span>** </span>
                      <span>{{ $t('crypto_withdraw_invoice_fee_description') }}</span>
                    </div>
                  </template>
                </b-table>
              </div>
            </b-row>
          </b-card>
        </template>
        <template #modal-footer>
          <b-button
            size="md"
            variant="success"
            @click="openConfirmModal"
          >
            <span v-if="!confirmPending">
              {{ $t('widrawModal.confirm') }}
            </span>
            <b-spinner
              v-else
              small
              size="10"
              variant="light"
              type="grow"
            />
          </b-button>
        </template>
      </b-modal>

      <b-modal
        v-model="otpModal"
        :title="$t('confirm_withdraw')"
        centered
        size="md"
        hide-footer
      >
        <validation-observer
          #default="{invalid}"
        >
          <b-row>
            <b-col>
              <div
                v-if="mobile"
                class="my-1"
              >
                {{ $t('withdraw_confirm_description', { mobile }) }}
              </div>
              <b-form
                class="auth-login-form mb-2"
              >
                <!-- pin -->
                <b-form-group>
                  <div class="d-flex justify-content-between">
                    <label for="code">{{ $t('confirm_withdraw_code') }}</label>
                  </div>
                  <validation-provider
                    #default="{ errors }"
                    :name="$t('confirm_withdraw_code')"
                    vid="code"
                    rules="required"
                  >
                    <b-input-group>
                      <b-form-input
                        id="code"
                        v-model="confirmCode"
                        name="code"
                        placeholder="0000"
                      />
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-form>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="6">
              <b-button
                block
                size="md"
                variant="warning"
                :disabled="verifyCounter !== 0"
                @click="resendSMS"
              >
                <div v-if="!resendOtpLoading">
                  <span>
                    {{ $t('resend') }}
                  </span>
                  <span v-if="verifyCounter !== 0">({{ verifyCounter }})</span>
                </div>
                <b-spinner
                  v-else
                  small
                  size="10"
                  variant="light"
                  type="grow"
                />
              </b-button>
            </b-col>
            <b-col cols="6">
              <b-button
                block
                size="md"
                :disabled="invalid || confirmCode.length < 4"
                variant="success"
                @click="verify"
              >
                <span v-if="!verifyLoading">
                  {{ $t('widrawModal.confirm') }}
                </span>
                <b-spinner
                  v-else
                  small
                  size="10"
                  variant="light"
                  type="grow"
                />
              </b-button>
            </b-col>
          </b-row>
        </validation-observer>
      </b-modal>
      <b-modal
        v-model="transactionIdModal"
        centered
        :title="$t('show_transaction_id')"
        ok-only
        :ok-title="$t('show_in_network')"
        no-close-on-backdrop
        :ok-disabled="!selectedTransactionNetwork"
        @ok="openTransaction"
      >
        <b-form-group
          id="popover-manual-2"
          label-for="transaction_id"
          :label="$t('transaction_id')"
        >
          <copy-to-clipboard
            :text="selectedTransactionId"
            @copy="copyTransaction"
          >
            <b-input-group class="mb-2">
              <b-form-input
                id="transaction_id"
                v-model="selectedTransactionId"
                readonly
                name="transaction_id"
                :placeholder="$t('transaction_id')"
              />
              <b-input-group-append is-text>
                <feather-icon
                  icon="CopyIcon"
                  size="20"
                />
              </b-input-group-append>
            </b-input-group>
          </copy-to-clipboard>
          <b-popover
            target="popover-manual-2"
            :show.sync="pop2"
            triggers="click"
            placement="bottom"
          >
            {{ $t('copied') }}
          </b-popover>
        </b-form-group>
      </b-modal>
    </b-overlay>
  </div></template>
<script>
import {
  BOverlay, BFormInput, BForm, BFormGroup, BCard, BRow, BButton, BImg, BCardBody, BModal, BAlert, BPopover,
  BCol, BSpinner, BTable, BPagination,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { required, cryptoWalletAddress, min_value } from '@validations'
import { QrcodeStream } from 'vue-qrcode-reader'
import CopyToClipboard from 'vue-copy-to-clipboard'
import moment from '@/libs/moment'
import CustomerWalletApis from '@/services/apis/customer-wallet'
import { toEnglishDigits, numberWithCommas } from '@/utils'

const customerWallet = new CustomerWalletApis()

export default {
  components: {
    BOverlay,
    BFormInput,
    BForm,
    BFormGroup,
    BCard,
    BRow,
    BButton,
    BImg,
    BCardBody,
    BCol,
    BModal,
    BSpinner,
    BAlert,
    QrcodeStream,
    BTable,
    BPagination,
    CopyToClipboard,
    BPopover,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      show: true,
      confirmModal: false,
      confirmPending: false,
      required,
      min_value,
      cryptoWalletAddress,
      symbol: '',
      coin: '',
      network: '',
      networks: [],
      price: '',
      fee: '',
      destinationAddress: '',
      sourceAddress: '',
      cards: [],
      currency: '',
      amount: '',
      status: '',
      date: '',
      items: [],
      result: '',
      error: '',
      showBarcode: false,
      otpModal: false,
      resendOtpLoading: false,
      verifyLoading: false,
      confirmCode: '',
      verifyCounter: 0,
      modalInvoiceColumns: null,
      helpers: {
        numberWithCommas,
      },
      totalTransactions: 0,
      currentpageTransactions: 1,
      perPageTransactions: 10,
      selectedTransactionId: '',
      selectedTransactionNetwork: '',
      pop2: false,
      transactionIdModal: false,
    }
  },

  computed: {
    fields() {
      return [
        {
          label: this.$t('coin'),
          key: 'currency_name',
          tdClass: 'nameOfTheClass',
        },
        {
          label: this.$t('Network'),
          key: 'network_name',
          tdClass: 'nameOfTheClass',
        },
        {
          label: this.$t('amount'),
          key: 'amount',
          tdClass: 'nameOfTheClass',
        },

        {
          label: this.$t('date'),
          key: 'created_at',
          tdClass: 'nameOfTheClass',
        },
        {
          label: this.$t('status'),
          key: 'status',
          tdClass: 'nameOfTheClass',
        },
        {
          label: '',
          key: 'transaction_id',
          tdClass: 'nameOfTheClass',
        },
      ]
    },
    mobile() {
      return this.$store.state.profile.profileInfo.mobile
    },

    invoiceHeader() {
      return [
        {
          key: 'address',
          label: this.$t('address'),
        },
        {
          key: 'network',
          label: this.$t('Network'),
        },
        {
          key: 'currency',
          label: this.$t('currency'),
        },
        {
          key: 'amount',
          label: this.$t('Amount'),
        },
        {
          key: 'fee',
          label: this.$t('Fee'),
        },
        {
          key: 'total',
          label: this.$t('total'),
        },
      ]
    },
  },
  watch: {
    pop2(val) {
      if (val) {
        setTimeout(() => {
          this.pop2 = false
        }, 2500)
      }
    },
    currentpageTransactions(val) {
      if (val) {
        this.getWalletTransactions()
      }
    },
  },

  mounted() {
    if (this.$route.params.coin_name && this.$route.params.coin_symbol && this.$route.params.network_name) {
      this.symbol = this.$route.params.coin_symbol
      this.coin = this.$route.params.coin_name
      this.network = this.$route.params.network_name
      this.show = true
      customerWallet.getWalletByAddress({
        currency: this.$route.params.coin_symbol,
        network: this.$route.params.network_name,
      }).then(({ data }) => {
        this.fee = data.results[0].network.fee
        this.sourceAddress = data?.results[0]?.network?.wallets[0]?.address

        this.getWalletTransactions()
      }).catch(() => {
        this.$router.push(`/${this.$i18n.locale}/crypto-wallets`)
      }).finally(async () => {
        await this.getWalletTransactions()
        this.show = false
      })
      // todo hardcoded here | do somthing about it
      this.networks.push(this.$route.params.network_name)
      if (this.$route.params.coin_symbol === 'usdt') {
        this.networks.push('bsc (BEP20)')
      }
    } else {
      this.$router.push(`/${this.$i18n.locale}/crypto-wallets`)
    }
  },

  methods: {
    openConfirmModal() {
      this.confirmPending = true
      let obj
      if (this.$route.params.coin_symbol === 'usdt') {
        obj = {
          amount: toEnglishDigits(this.price),
          address: this.destinationAddress,
          currency: this.network === 'trc20' ? 'usdt' : 'bsc-usd',
          network: this.network === 'trc20' ? 'trc20' : 'bsc',
        }
      } else {
        obj = {
          amount: toEnglishDigits(this.price),
          address: this.destinationAddress,
          currency: this.$route.params.coin_symbol,
          network: this.$route.params.network_name,
        }
      }

      customerWallet.withdrawalSendCode(obj).then(() => {
        this.verifyCounter = 60
        this.countDownTimer()
        this.otpModal = true
      }).finally(() => {
        this.confirmPending = false
      })
    },
    async getWalletTransactions() {
      this.show = true
      await customerWallet.withdrawTransactions({
        current_page: this.currentpageTransactions,
        per_page: this.perPageTransactions,
      }).then(res => {
        this.totalTransactions = res.data.results.paginate.total
        this.currentpageTransactions = res.data.results.paginate.current_page
        this.perPageTransactions = Number(res.data.results.paginate.per_page)
        this.items = res.data.results.data
      }).finally(() => {
        this.show = false
      })
    },
    getFormattedDate(date) {
      return moment(date).format('YYYY-MM-DD')
    },
    onDecode(result) {
      this.result = result
      this.destinationAddress = result
      this.showBarcode = false
      this.error = ''
    },

    async onInit(promise) {
      try {
        await promise
      } catch (error) {
        this.showBarcode = false
        if (error.name === 'NotAllowedError') {
          this.error = 'barcodeError.camera-access'
        } else if (error.name === 'NotFoundError') {
          this.error = 'barcodeError.camera-error'
        } else if (error.name === 'NotReadableError') {
          this.error = 'barcodeError.use-camera'
        } else if (error.name === 'OverconstrainedError') {
          this.error = 'barcodeError.camera-installed'
        } else if (error.name === 'StreamApiNotSupportedError') {
          this.error = 'barcodeError.not-support'
        } else if (error.name === 'InsecureContextError') {
          this.error = 'barcodeError.use-HTTPS'
        } else {
          this.error = `ERROR: Camera error (${error.name})`
        }
      }
    },
    resendSMS() {
      this.resendOtpLoading = true
      let obj
      if (this.$route.params.coin_symbol === 'usdt') {
        obj = {
          amount: toEnglishDigits(this.price),
          address: this.destinationAddress,
          currency: this.network === 'trc20' ? 'usdt' : 'bsc-usd',
          network: this.network === 'trc20' ? 'trc20' : 'bsc',
        }
      } else {
        obj = {
          amount: toEnglishDigits(this.price),
          address: this.destinationAddress,
          currency: this.$route.params.coin_symbol,
          network: this.$route.params.network_name,
        }
      }
      customerWallet.withdrawalResendCode(obj).then(({ data }) => {
        this.verifyCounter = 60
        this.countDownTimer()
        this.$swal({
          title: data?.message,
          icon: 'success',
          timer: 2500,
          showConfirmButton: false,
        })
      }).finally(() => {
        this.resendOtpLoading = false
      })
    },
    verify() {
      let obj = null
      this.verifyLoading = true
      // hard coded
      if (this.$route.params.coin_symbol === 'usdt') {
        obj = {
          amount: toEnglishDigits(this.price),
          address: this.destinationAddress,
          currency: this.network === 'trc20' ? 'usdt' : 'bsc-usd',
          network: this.network === 'trc20' ? 'trc20' : 'bsc',
          code: this.confirmCode,
        }
      } else {
        obj = {
          amount: toEnglishDigits(this.price),
          address: this.destinationAddress,
          currency: this.$route.params.coin_symbol,
          network: this.$route.params.network_name,
          code: this.confirmCode,
        }
      }

      customerWallet.withdrawalCryptoWallet(obj).then(({ data }) => {
        this.$swal({
          title: data?.message,
          icon: 'success',
          timer: 2500,
          showConfirmButton: false,
        })
        setTimeout(() => {
          this.confirmModal = false
          this.otpModal = false
        }, 3500)
        this.$router.push(`/${this.$i18n.locale}/crypto-wallets`)
      }).finally(() => {
        this.verifyLoading = false
      })
    },
    countDownTimer() {
      if (this.verifyCounter > 0) {
        setTimeout(() => {
          this.verifyCounter -= 1
          this.countDownTimer()
        }, 1000)
      }
    },
    changeNetwork() {
      this.show = true

      let obj
      if (this.$route.params.coin_symbol === 'usdt') {
        obj = {
          currency: this.network === 'trc20' ? 'usdt' : 'bsc-usd',
          network: this.network === 'trc20' ? 'trc20' : 'bsc',
        }
      } else {
        obj = {
          currency: this.$route.params.coin_symbol,
          network: this.$route.params.network_name,
        }
      }

      customerWallet.getWalletByAddress(obj).then(({ data }) => {
        console.log(data)
        this.fee = data.results[0].network.fee
        this.sourceAddress = data?.results[0]?.network?.wallets[0]?.address
      }).finally(() => {
        this.show = false
      })
    },
    setInvoiceDetails() {
      this.confirmModal = true
      this.modalInvoiceColumns = [{
        address: this.destinationAddress,
        network: this.network,
        currency: this.coin,
        amount: this.helpers.numberWithCommas(this.price),
        fee: this.fee,
        total: this.helpers.numberWithCommas(Number(this.price) - Number(this.fee)),
      }]
    },

    triggerTransactionModal({ transaction_id, network_name }) {
      this.transactionIdModal = true
      // eslint-disable-next-line camelcase
      this.selectedTransactionId = transaction_id
      // eslint-disable-next-line camelcase
      this.selectedTransactionNetwork = network_name
    },
    openTransaction() {
      if (this.selectedTransactionNetwork === 'TRC20' && this.selectedTransactionId) {
        window.open(`https://tronscan.org/#/transaction/${this.selectedTransactionId}`, '_blank')
      } else if (this.selectedTransactionNetwork === 'BSC' && this.selectedTransactionId) {
        window.open(`https://bscscan.com/tx/${this.selectedTransactionId}`, '_blank')
      }
      this.transactionIdModal = false
    },
    copyTransaction() {
      this.pop2 = true
    },

  },
}
</script>
<style lang="scss">
.invoiceModal .card-title{
  color: $primary;
}
.card-border {
  border-right: 1px solid #e3e1e9;
}
.transaction-card {
  background-color: #efefef;
  padding: 15px;
  border-radius: 5px;
}
.nameOfTheClass {
  padding: 20px !important;
}
.table-invoice-crypto-withdraw .table{
  background: transparent !important;
}
.table-invoice-crypto-withdraw .table tbody{
  background: transparent !important;
}
.table-invoice-crypto-withdraw .table tr{
  background: transparent !important;
}
.table-invoice-crypto-withdraw .table td{
  color: #283046 !important;
  padding: 6px !important;
}
.table-invoice-crypto-withdraw .table td::before{
  width: 14rem !important;
}
@media screen and (max-width:1024px) {
  .table-invoice-crypto-withdraw .table td::before{
    width: 4rem !important;
  }
}
</style>
